.authoritive__view {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.authoritive__form {
	width: 400px;

	background-color: #fff;

	.form-wrapper {
		padding: 25px;
	}

	.ant-layout-header {
		padding: 0 30px;

		h2 {
			color: white;
			margin: 0;
			font-size: 16px;
		}
	}

	.ant-layout-content {
		padding: 20px;
		background: #fff;

		h3 {
			text-align: center;
		}

		form {
			width: 100%;
			margin: 20px 0;
			display: flex;
			flex-direction: column;
		}
	}
}

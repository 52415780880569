.sidebar {
	width: 100%;
	min-height: 100vh;
	padding: 20px 10px;
	color: #f7f7f7;

	.ant-divider {
		border-color: rgba($color: #aaaaaa, $alpha: 0.4);
		border-width: 2px;
	}

	.profile {
		width: 100%;
		padding: 30px 0 10px 0;

		.controls {
			margin-top: 10px;
			width: 100%;

			display: flex;
			flex-direction: row;

			.username {
				padding: 0.5px 10px;
				border-radius: 30px;
				border: solid 1px green;

				color: green;
				cursor: pointer;

				svg {
					transform: translateY(1px) scaleY(0.8) scaleX(0.9);
				}
			}
		}
	}

	.list {
		padding: 0;

		li {
			list-style: none;
			font-size: 0.9rem;
			font-weight: 500;
			margin: 5px 0;

			border-radius: 5px;
			overflow: hidden;

			a {
				padding: 10px 20px;
				display: block;
				color: inherit;

				background: rgba($color: #aaaaaa, $alpha: 0);
				transition: 0.15s ease;

				&.active {
					background: rgba($color: #aaaaaa, $alpha: 0.2);
				}

				&:hover {
					background: rgba($color: #aaaaaa, $alpha: 0.4);
				}

				.link {
					width: 100%;
					display: flex;
					flex-direction: row;
					gap: 20px;
					align-items: center;

					svg {
						transform: translateY(2px);
					}
				}
			}
		}
	}
}

.scoreSheet {
	width: calc(100vw - 250px);
	padding: 10px;
	height: 450px;

	border: 1px solid lightgrey;

	.Spreadsheet {
		width: 100%;
		overflow-x: scroll;
	}
}
.scoreTable {
	width: 100%;
}

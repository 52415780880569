.logSheet {
	width: calc(100vw - 250px);
	padding: 20px;
	height: 400px;
	border: 1px solid lightgrey;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.userLogList {
		width: 100%;
		height: 300px;
		overflow-y: auto;

		border: 1px solid rgba($color: #aaaaaa, $alpha: 0.5);
	}
}
.scoreTable {
	width: 100%;
}

* {
	box-sizing: border-box;
}

:root {
	--color-primary: black;
	--ff: "Roboto", "Montserrat", "Ralewar", sans-serif;
}

body {
	width: 100%;
	margin: 0;
	padding: 0;
	font-family: var(--ff);
	overflow-x: hidden;
}

#root {
	width: 100vw;
	min-height: 100vh;
}

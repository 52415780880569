.dashboard {
	width: 100%;
	min-height: 100vh;
}
.playground {
	padding: 20px;
}
.thumbnail {
	height: 30px;
}
.pageHeader {
	width: 100%;
	padding: 20px 20px;
	border-radius: 10px;
	margin-bottom: 30px;

	background-color: #fff;

	h2 {
		font-weight: 600;
		margin: 0;

		display: flex;
		flex-direction: row;
		gap: 15px;
		justify-content: space-between;
		align-items: center;
	}
}
.image-upload-field {
	width: 100%;

	.fileList {
		margin-top: 5px;
		width: 100%;

		.fileList-item {
			width: 100%;
			padding: 0px 5px;
			background: rgb(235, 235, 235);
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			small {
				color: grey;
			}
		}
	}
}
.options {
	margin-top: 20px;

	.option {
		padding: 10px;
		border: 1px solid #a3a3a3;
		margin: 10px 0;

		position: relative;

		.correctAnswerCheckbox {
			position: absolute;
			top: 7.5px;
			right: 15px;

			cursor: pointer;
			padding: 3px 15px;
			font-size: 0.7rem;

			background: #fff;
			color: black;
			font-weight: 400;
			transition: 0.15s ease;

			border-radius: 25px;
			border: solid 1px rgba(211, 211, 211, 1);
			z-index: 3;

			&.active {
				background: #0f0;
				color: white;
				font-weight: 600;

				border: solid 1px rgba(211, 211, 211, 0);
			}
		}
	}
}

.login__view {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login__form {
	width: 400px;
	padding: 20px;

	background-color: #fff;

	h3 {
		text-align: center;
	}

	form {
		width: 100%;
		margin: 20px 0;
		display: flex;
		flex-direction: column;
	}
}
